input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: normal;
  white-space: normal;
  background: none;
  outline: none;
  cursor: text;
}
